const markAsRead = {
	id: 'markAsRead',
	selectionType: 'multiple', // [single|multiple|general]
	label: 'actions.alert.markAsRead',
	functionality: 'EDIT_ALERT', // set the functionality if needed
	checkAvailability: function (registries) {
		return registries && registries.read == false;
	},
	runAction: function (action, model, registries) {
		for (let i = 0; i < registries.length; i++) {
			const url = this.$store.getters.getModelByName('alert').url.patch;
			const params = { read: true };
			const pk = { id: registries[i].id };

			this.$puiRequests.patchRequest(
				url,
				params,
				(response) => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
				},
				(error) => {
					this.$puiNotify.error(this.$t('pui9.save.error'));
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
				},
				pk
			);
		}
	}
};

export default {
	gridactions: [markAsRead],
	formactions: []
};
